.home-page{
    width:80%;
    margin: 0 auto;
    text-align: center;
}
.btn-commencer{
    background: #0088CE;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 2.5rem;
}
.home-container{
    height: 100vh ;
    width: calc(100vw - 30px);
    text-align: center;
    margin: 0 auto;
}
.bloc-intro{
    width:50%;
    display: inline-block;
    font-size: xx-large;
}
h1{
    color: #0088CE;
}
.txt-accueil{
    font-size: 2rem;
}
#country{
    font-size: 2rem;
}
/* Styles pour les mobiles (xs) */
@media (max-width: 575.98px) {
    h1{
        font-size: 1.8rem;
    }
    p, select.form-select{
        font-size: 1.2rem;
    }
    .btn-commencer{
        font-size: 1.6rem;
    }
    .txt-accueil{
        font-size: 1.4rem;
    }
    .home-container{
        height: 100vh ;
        width: calc(100vw - 30px);
        text-align: center;
        margin: 0 auto;
    }
}

/* Styles pour les tablettes (sm) */
@media (min-width: 576px) {
    h1{
        font-size: 3.5rem;
    }
    p, select.form-select{
        font-size: 2rem;
    }
    .btn-commencer{
        font-size: 2.2rem;
    }
}