.page-content{
    width: calc(100vw - 30px);
    margin: 0 auto;
    text-align: center;
}
h1{
    color: #0088CE;
}
h1.title{
    font-size: 2.8rem;
}
textarea.form-control{
    border:2px solid #0088CE;
}
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
}
.zone-range{
    width: 80%;
    margin: 0 auto;
}
.spinner-custom {
    width: 8rem;
    height: 8rem;
}
.text-answer{
    font-size: 2rem;
    width:90%;
    margin: 0 auto;
}
.form-range{
    margin-top:3rem;
    margin-bottom:3rem;
}
.form-check{
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
}
.form-check-label2{
    font-size: 1.8rem;
    color:rgb(56, 52, 52);
}


/* Si tu veux que les labels s'adaptent à des textes plus longs, ajuste la largeur */
.form-check-label {
    width: auto;
    max-width: 100%; /* Ajuste selon le besoin */
}
/* Styles pour les mobiles (xs) */
@media (max-width: 575.98px) {
    h1.title{
        font-size: 1.5rem;
    }
    p, select, textarea.form-control, label{
        font-size: 1.2rem;
    }
    .btn-commencer{
        font-size: 1.6rem;
    }
    .text-answer{
        font-size: 1.2rem;
        /* margin-left: 25px; */
    }
    .text-answer{
        font-size: 1rem;
        margin-left: 0px;
    }
    .form-check-label2{
        font-size: 1.1rem;
        color:rgb(56, 52, 52);
    }
    .form-check{
        padding-left:0;
    }
}

/* Styles pour les tablettes (sm) */
@media (min-width: 576px) {
    h1{
        font-size: 3rem;
    }
    p, select, textarea.form-control, label{
        font-size: 2.2rem;
    }
    .btn-commencer{
        font-size: 2.2rem;
    }
}