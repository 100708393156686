.end-page {
    text-align: center;
    margin-top: 50px;
}

.btn-restart {
    background: #0088CE;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 2.5rem;
   
}
/* Styles pour les mobiles (xs) */
@media (max-width: 575.98px) {
    h1{
        font-size: 1.8rem;
    }
    p, select.form-select{
        font-size: 1.2rem;
    }
    .btn-restart {
        font-size: 1.6rem;
    }
}

/* Styles pour les tablettes (sm) */
@media (min-width: 576px) {
    h1{
        font-size: 3.5rem;
    }
    p, select.form-select{
        font-size: 2.5rem;
    }
    .bbtn-restart {
        font-size: 2.2rem;
    }
}
